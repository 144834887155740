& .book-detail-container {
  width: 1400px;
  margin: 40px auto 0;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1400px) {
    min-width: 320px;
    max-width: 440px;
    width: calc(100vw - 40px);
  }
  & .class-detail-container {
    display: flex;
    padding-bottom: 24px;
    border-bottom: 1px solid #bababa;
    @media screen and (max-width: 1400px) {
      padding-bottom: 0;
      border-bottom: none;
      flex-wrap: wrap;
    }
    & .class-container {
      display: flex;
      cursor: pointer;

      &.no-click {
        cursor: default;
      }

      & .right-arrow {
        margin: 0 12px;
      }
    }
  }
  & .title-container {
    margin-top: 16px;
    & .title-line {
      height: 56px;
      display: flex;
      @media screen and (max-width: 1400px) {
        height: 36px;
      }
      & .title {
        margin-top: auto;
        font-size: 27px;
        font-weight: bold;
        display: flex;
      }
      & .share-button {
        margin-left: auto;
        width: 56px;
        height: 56px;
        padding: 10px;
        border: 1px solid #1e76af;
        border-radius: 8px;
        cursor: pointer;
      }
    }
    & .sub-title-line {
      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;
      font-size: 16px;
      @media screen and (max-width: 1400px) {
        display: none;
        font-size: 12.5px;
        text-align: left;
      }
      & div {
        margin: 0 20px 5px 0;
        text-align: left;
        @media screen and (max-width: 1400px) {
          width: calc(100vw - 40px);
          min-width: 320px;
          max-width: 440px;
          margin-right: 0;
        }
        &.sub-title-text {
          font-weight: bold;
        }
      }
      & .blue {
        color: #1e76af;
      }
    }
  }
  & .book-detail-body {
    margin-top: 40px;
    display: flex;
    @media screen and (max-width: 1400px) {
      flex-direction: column;
      margin-top: 16px;
    }
    & .left {
      width: 594px;
      background: #f5f5f5 0% 0% no-repeat padding-box;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 1400px) {
        min-width: 320px;
        max-width: 440px;
        width: calc(100vw - 40px);
      }
      & .book-img {
        width: 400px;
        margin: 60px auto 0;
        padding-bottom: 30px;
        @media screen and (max-width: 1400px) {
          width: 200px;
        }
        & img {
          width: 400px;
          background: #ffffff 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 40px #00000029;
          @media screen and (max-width: 1400px) {
            width: 200px;
          }
        }
      }
      & .preview-icon {
        margin: auto;
        cursor: pointer;
        margin-bottom: 40px;
        width: fit-content;
      }
    }
    & .right {
      width: 749px;
      border-top: 1px solid #e8e8e8;
      margin-left: 55px;
      @media screen and (max-width: 1400px) {
        min-width: 320px;
        max-width: 440px;
        width: calc(100vw - 40px);
        margin: 20px auto;
        border-top: none;
        & .title-container {
          & .title-line {
            & .title {
              font-size: 20px;
              text-align: left;
              margin-top: 0;
            }
          }
          & .sub-title-line {
            display: flex;
            flex-direction: column;
          }
        }
      }
      & .tags-container {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        & .tag {
          border: 1px solid #e8e8e8;
          border-radius: 4px;
          padding: 4px 12px;
          margin-right: 8px;
          margin-bottom: 8px;
          font-size: 12px;
        }
      }
      & .rating-container {
        display: flex;
        margin-top: 12px;
        & .star {
          margin: 2px 6px 0 0;
          @media screen and (max-width: 1400px) {
            margin: 2px 9px 0 0;
          }
        }
        & .rating {
          font-size: 20px;
          font-weight: bold;
          margin-left: 8px;
          @media screen and (max-width: 1400px) {
            font-size: 15px;
            margin-top: 3px;
            margin-left: 20px;
          }
        }
        & .review-container {
          display: flex;
          cursor: pointer;
          & .review-count {
            margin-left: 16px;
            margin-top: 5px;
            @media screen and (max-width: 1400px) {
              font-size: 12.5px;
              margin-left: 20px;
            }
          }
          & .right-arrow {
            width: 20px;
            height: 20px;
            display: flex;
            background: #f5f5f5 0% 0% no-repeat padding-box;
            border-radius: 30px;
            margin-left: 4px;
            margin-top: 6px;
            @media screen and (max-width: 1400px) {
              margin-top: 3px;
              margin-left: 12px;
            }
            & div {
              margin-left: 7px;
              margin-top: -3px;
            }
          }
        }
      }
      & .name-container {
        display: flex;
        margin-top: 16px;
        padding-bottom: 20px;
        border-bottom: 1px solid #e8e8e8;
        @media screen and (max-width: 1400px) {
          font-size: 12.5px;
        }
        & div {
          height: 15px;
          width: 1px;
          margin: auto 10px;
          border-left: 1px solid #bababa;
        }
      }
      & .price-container {
        display: flex;
        margin-top: 32px;
        @media screen and (max-width: 1400px) {
          margin-top: 8px;
          height: 32px;
        }
        & .title {
          width: 95px;
          text-align: left;
          height: 24px;
          margin-top: 8px;
          @media screen and (max-width: 1400px) {
            width: 86px;
            font-size: 13px;
          }
        }
        & .sale-percent {
          background-color: #1e76af;
          @media screen and (max-width: 1400px) {
            font-size: 11px;
            height: 20px;
            padding: 2px 4px;
            margin-top: 10px;
          }
        }
        & #price-container {
          & .price-before {
            text-decoration: none;
            font-size: 12px;
            margin-left: 10px;
            text-decoration: line-through;
            color: #777777;
          }
          @media screen and (max-width: 1400px) {
            height: fit-content;
            & .price-after {
              // margin-left: 8px;
              & span {
                font-size: 15px;
              }
            }
          }
        }
      }
      & .row-container {
        display: flex;
        margin-top: 32px;
        @media screen and (max-width: 1400px) {
          margin-top: 12px;
          font-size: 13px;
          text-align: left;
        }
        & .title {
          width: 95px;
          text-align: left;
          height: 24px;
          @media screen and (max-width: 1400px) {
            min-width: 86px;
            width: 86px;
            font-size: 13px;
          }
        }
        & .sale-percent {
          background-color: #1e76af;
        }
        & .question-button {
          margin-left: 8px;
          margin-top: 2px;
          cursor: pointer;
          @media screen and (max-width: 1400px) {
            display: none;
          }
        }
        & .change-address-button {
          margin: 0 16px;
          border: 1px solid #bababa;
          border-radius: 4px;
        }
      }
      & .shipping-fee {
        margin-top: 12px;
        text-align: left;
        margin-left: 95px;
        @media screen and (max-width: 1400px) {
          font-size: 13px;
          margin-left: 86px;
        }
      }
      & .extra {
        border-radius: 8px;
        width: 471px;
        height: 48px;
        margin-top: 24px;
        padding: 14px 16px;
        font-size: 14px;
        text-align: left;
        display: flex;
        @media screen and (max-width: 1400px) {
          width: 100%;
        }
        &.item {
          background: #f5f5f5 0% 0% no-repeat padding-box;
          & .gift-box {
            margin-right: 12px;
            margin-top: -2px;
          }
        }
        &.exhibition {
          background: #f3f8fb 0% 0% no-repeat padding-box;
          // margin-top: 12px;
          cursor: pointer;
        }
      }
      & .qty-selection-container {
        border: 1px solid #e8e8e8;
        border-radius: 12px;
        width: 750px;
        height: 200px;
        margin-top: 48px;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 1400px) {
          min-width: 320px;
          width: calc(100vw - 40px);
          max-width: 440px;
          height: fit-content;
          padding: 16px;
        }
        & .row {
          height: 100px;
          display: flex;
          @media screen and (max-width: 1400px) {
            flex-wrap: wrap;
            height: fit-content;
          }
          & .text {
            margin-top: 50px;
            margin-left: 40px;
            font-size: 14px;
            @media screen and (max-width: 1400px) {
              margin: 16px 50px 0 24px;
            }
          }
          & .selection-container {
            line-height: 48px;
            height: 48px;
            display: flex;
            border: 1px solid #bababa;
            border-radius: 8px;
            margin-top: 36px;
            margin-left: 40px;
            @media screen and (max-width: 1400px) {
              margin: 0;
              margin-left: auto;
            }
            & button {
              width: 48px;
              font-size: 32px;
              border-radius: 8px;
            }
            & div {
              width: 72px;
              font-size: 18px;
            }
          }
          & .total-amount-text {
            margin-left: auto;
            margin-top: 54px;
            font-size: 14px;
          }
          & .total-price {
            font-size: 30px;
            margin-left: 16px;
            margin-top: 39px;
          }
          & .unit {
            margin: 54px 36px 0 8px;
            @media screen and (max-width: 1400px) {
              margin-right: 0;
            }
          }
          & .button {
            border: 1px solid #1e76af;
            border-radius: 8px;
            height: 56px;
            margin-top: 20px;
            @media screen and (max-width: 1400px) {
              height: 48px;
              margin-top: 0;
            }
            &.like {
              width: 56px;
              margin-left: auto;
              display: flex;
              @media screen and (max-width: 1400px) {
                margin-left: 0;
                width: 48px;
              }
              & div {
                margin: auto;
              }
            }
            &.cart,
            &.buy {
              width: 155px;
              margin-left: 12px;
              padding: 16px 20px;
              @media screen and (max-width: 1400px) {
                min-width: 110px;
                width: calc(50vw - 70px);
                max-width: 170px;
                padding: 15px;
                margin-left: auto;
              }
              color: #1e76af;
              & div {
                display: flex;
                & div {
                  margin-left: auto;
                  margin-top: 1px;
                }
              }
            }
            &.buy {
              background-color: #1e76af;
              color: white;
              margin-right: 36px;
              @media screen and (max-width: 1400px) {
                margin-right: 0;
              }
            }
          }
        }
      }
      & .unavailable-information-presenter {
        width: 750px;
        margin-top: 48px;
        border: 1px solid #eaeaea;
        border-radius: 12px;
        padding: 36px;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 1400px) {
          width: auto;
          padding: 20px;
        }
        & .row {
          display: flex;
          @media screen and (max-width: 1400px) {
            flex-wrap: wrap;
            text-align: left;
          }
          & .information-text {
            font-weight: bold;
            font-size: 24px;
            @media screen and (max-width: 1400px) {
              font-size: 18px;
            }
          }
          & .divider {
            width: 1px;
            height: 15px;
            background-color: #bababa;
            margin: 20px 8px 0;
          }
          & .available-date-text {
            margin-top: 14px;
            &.first {
              margin-left: auto;
            }
          }
          &.second {
            margin-top: 44px;
            @media screen and (max-width: 1400px) {
              margin-top: 24px;
            }
          }
          & .button {
            border: 1px solid #1e76af;
            border-radius: 8px;
            height: 56px;
            margin-top: 20px;
            &.like {
              width: 56px;
              margin-left: auto;
              display: flex;
              & div {
                margin: auto;
              }
            }
            &.cart,
            &.buy {
              width: 155px;
              margin-left: 12px;
              padding: 16px 20px;
              color: #1e76af;
              & div {
                display: flex;
                & div {
                  margin-left: auto;
                  margin-top: 1px;
                }
              }
            }
            &.buy {
              background-color: #1e76af;
              color: white;
              margin-right: 36px;
            }
          }
        }
      }
    }
  }
}
