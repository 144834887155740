.boardTsearch {
  border-top: 1px #bababa solid;
}

@media screen and (max-width: 1400px) {
  & .MuiList-root {
    & .MuiListItem-root {
      padding: 0 !important;
    }
  }
}

.cartMList {
  width: 996px;

  & .cart-header-container {
    height: 45px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 8px;
    border-bottom: 1px #e8e8e8 solid;
    & .all-selected {
      display: flex;
      padding-left: 16px;
      & .title-text {
        line-height: 45px;
      }
      @media screen and (max-width: 1400px) {
        padding-left: unset;
        & .MuiCheckbox-root {
          padding: 0;
          margin-right: 10px;
        }
      }
    }
    & .aBtn {
      margin-left: 10px;
      & .del-btn {
        border: 1px solid #bababa;
        & .text-color {
          color: #5a5a5a;
        }
      }
      // & .favorite {
      //   color: rgb(160, 11, 11);
      // }
    }
    @media screen and (max-width: 1400px) {
      justify-content: space-between;
    }
  }

  @media screen and (max-width: 1400px) {
    width: calc(100vw - 40px);
    min-width: 320px;
    max-width: 440px;
    margin: 0 auto;

    & .MuiListItem-container {
      margin-top: 10px;
    }
    & .MuiListItemText-root {
      margin-left: auto;
    }
  }

  & .shop-row {
    margin-bottom: 60px;
    @media screen and (max-width: 1400px) {
      margin-bottom: 30px;
      & .MuiListItem-root {
        padding: 15px 0px;
        align-items: start;
      }
    }
    & .shop-title-container {
      @media screen and (max-width: 1400px) {
        border-radius: 8px;
        // margin-top: 20px;
      }
      height: 60px;
      background-color: #f3f8fc;
      font-weight: bold;
      color: #2d719e;
      display: flex;
      & .shop-title {
        margin: auto 20px;
        cursor: pointer;
      }
      & .right-arrow {
        margin: auto 0;
        cursor: pointer;
      }
      &.modoo {
        color: black;
        background-color: #f5f5f5;
      }
    }

    @media screen and (max-width: 1400px) {
      & .MuiListItemIcon-root {
        width: 24px !important;
        min-width: 24px;
        & .MuiCheckbox-root {
          padding: 0;
        }
      }
    }
  }

  & div.cBdx {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 20px 0;
    // border-bottom: 1px #e8e8e8 solid;
    overflow: hidden;

    @media screen and (max-width: 1400px) {
      padding: 0 0 12px 0;
    }
    & .mobile-info-container {
      display: flex;
      flex-direction: column;
      height: fit-content;
      margin-left: 24px;
    }
    & .imgArea {
      & img {
        margin-left: 10px;
        width: 80px;
        border-radius: 8px;
        box-shadow: 0px 5px 10px rgb(0 0 0 / 29%);
        cursor: pointer;
        // @media screen and (max-width: 1400px) {
        //   width: 60px;
        // }
      }
    }
    & .txtArea {
      margin: 0 0 0 32px;
      @media screen and (max-width: 1400px) {
        margin-left: 0;
      }
      & a {
        outline: 0;
        color: #a7a7a7;
        text-decoration: none;
      }
      & .ctt {
        font-size: 14px;
        color: #000;
        line-height: 1.2;
        font-weight: 600;
        padding: 0 0 10px 0;
        width: 310px;
        @media screen and (max-width: 1400px) {
          width: 215px;
        }
      }
      & .ctn {
        font-size: 14px;
        color: #000;
        line-height: 1.2;
      }
    }
    & .cTnum {
      position: absolute;
      left: 50%;
      top: 50%;
      display: inline-block;
      width: 168px;
      height: 48px;
      padding: 0 48px;
      margin: -24px 0 0 0;
      border: 1px #bababa solid;
      border-radius: 8px;
      @media screen and (max-width: 1400px) {
        position: relative;
        left: unset;
        top: unset;
        margin: 24px 0 0;
        width: 100px;
        height: 32px;
        padding: 0 32px;
        border-radius: 4px;
      }

      & .bMinusBtn {
        position: absolute;
        left: 0;
        top: 0;
        width: 48px;
        height: 48px;
        background: url(../../imgs/bg_btn_minus.png) 50% 50% no-repeat;
        background-size: 48px auto;
        @media screen and (max-width: 1400px) {
          width: 32px;
          height: 32px;
          background-size: 32px auto;
        }
      }
      & input {
        //input:focus { outline: none !important; border-color: #d6a8e9; box-shadow: 0 0 10px #d6a8e9; }
        &[type="text"] {
          width: 100%;
          color: #000;
          background: #fff;
          border-radius: 8px;
          margin: 0;
          box-sizing: border-box;
          vertical-align: middle;
          @media screen and (max-width: 1400px) {
            font-size: 12px;
          }
        }
        &:focus-visible {
          outline: none !important;
        }
        height: 46px;
        line-height: 46px;
        font-size: 18px;
        text-align: center;
        padding: 0;
        border: 0;
        @media screen and (max-width: 1400px) {
          height: 30px;
          line-height: 30px;
        }
      }
      & .bPlusBtn {
        position: absolute;
        right: 0;
        top: 0;
        width: 48px;
        height: 48px;
        background: url(../../imgs/bg_btn_plus.png) 50% 50% no-repeat;
        background-size: 48px auto;
        @media screen and (max-width: 1400px) {
          width: 32px;
          height: 32px;
          background-size: 32px auto;
        }
      }
    }
    & .price {
      position: absolute;
      left: 72%;
      top: 50%;
      margin: -12px 0 0 0;
      @media screen and (max-width: 1400px) {
        display: flex;
        height: 22px;
        margin: 12px 0 0;
        position: relative;
        left: unset;
        top: unset;
        justify-content: start;
      }
      & * {
        vertical-align: middle;
      }
      & > div {
        display: inline-block;
      }

      & .dc {
        width: 42px;
        height: 25px;
        text-align: center;
        margin: 0 12px 0 0;
        background: $primaryColor;
        border-radius: 4px;
        & span {
          font-size: 12px;
          color: #fff;
          line-height: 23px;
          font-weight: 600;
        }
        @media screen and (max-width: 1400px) {
          padding: 2px 4px;
          width: fit-content;
          height: fit-content;
          display: flex;
          & span {
            font-size: 11px;
            line-height: 16px;
            margin: auto;
          }
        }
      }
      & .totalWon {
        position: relative;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.2;
        // padding: 0 12px 0 0;
        margin-right: 12px;
        @media screen and (max-width: 1400px) {
          font-size: 14px;
          line-height: 20px;
          // margin-top: 3px;
          // & span {
          //   font-size: 12px;
          //   vertical-align: revert;
          // }
        }
      }
      & .preWon {
        font-size: 16px;
        color: #777;
        line-height: 1.2;
        text-decoration: line-through;
        @media screen and (max-width: 1400px) {
          font-size: 12px;
          line-height: 20px;
          // margin-top: 5px;
        }
      }
    }
  }
  & .delArea {
    height: 14vh;
    @media screen and (max-width: 1400px) {
      height: 100%;
      margin-bottom: auto;
    }
    & .lDelBtn {
      width: 24px;
      height: 24px;
      background: url(../../imgs/x_button.svg) left top no-repeat;
      background-size: contain;
      position: absolute;
      right: -18px;
    }
  }
}
