& .mainInfo-wrapper {
  height: 179px;
  display: flex;
  align-items: center;
  // @media screen and (max-width: 1400px) {
  //   height: 247px;
  //   display: block;
  // }

  & .mainInfo-main {
    min-width: 364px;
    min-height: 179px;
    background: var(---1e76af-key-color-) 0% 0% no-repeat padding-box;
    background: #1e76af 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 6px #00000029;
    border-radius: 8px;

    & .user-info {
      padding: 55px 40px 35px 40px;
      & .user-hello {
        height: 24px;
        text-align: left;
        font: normal normal normal 16px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #ffffff;
      }
      & .username-button {
        display: flex;
        flex-direction: column;
        gap: 8px;
        & .username-wrapper {
          display: flex;
          padding-top: 4px;
          & .username {
            text-align: left;
            font: normal normal bold 28px/40px Noto Sans KR;
            letter-spacing: 0px;
            color: #ffffff;
          }
          & .username-unit {
            text-align: left;
            font: normal normal normal 28px/40px Noto Sans KR;
            letter-spacing: 0px;
            color: #ffffff;
          }
        }
        & .modi-button-box {
          padding-top: 12px;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          & .modi-button {
            display: flex;
            width: 110px;
            height: 32px;
            border: 1px solid #ffffff;
            border-radius: 4px;
            background: #1e76af 0% 0% no-repeat padding-box;
            padding: 0;
            align-items: center;
            justify-content: center;
            & .button-title {
              text-align: left;
              font: normal normal normal 13px/24px Noto Sans KR;
              letter-spacing: 0px;
              color: #ffffff;
            }
            & .userInfo-img img {
              // margin-top: 4px;
              height: 16px;
              width: 16px;
              cursor: pointer;
            }
        }
        }
      }
    }
  }
  & .myPage-info {
    top: 288px;
    left: 496px;
    width: 1164px;
    height: 163px;
    /* UI Properties */
    background: var(---f3f8fb-color-bg-) 0% 0% no-repeat padding-box;
    background: #f3f8fb 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    & .info-wrapper {
      cursor: pointer;
      @media screen and (min-width: 1400px) {
        width: 195px;
        margin: auto;
      }
      & .info-title {
        @media screen and (min-width: 1400px) {
          align-self: center;
          width: 150px;
          margin: auto;
        }
        color: var(---000000-text-);
        text-align: left;
        font: normal normal normal 14px/20px Noto Sans KR;
        font-weight: bold;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
      & .info-content-wrapper {
        height: 19px;
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        & .info-content {
          color: var(---1e76af-key-color-);
          text-align: center;
          font: normal normal bold 24px/35px Noto Sans KR;
          letter-spacing: 0px;
          color: #1e76af;
          opacity: 1;
        }
        & .info-unit {
          color: var(---000000-text-);
          text-align: right;
          margin-left: 4px;
          margin-top: 4px;
          font: normal normal normal 13px/19px Noto Sans KR;
          font-weight: bold;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }
      }
    }
    & .vl:not(:last-of-type) {
      border-left: 1px solid#BABABA;
      width: 0;
      height: 67px;
      margin: auto 0;
    }
  }
  // @media screen and (max-width: 1400px) {
  //   & .mainInfo-main {
  //     min-height: auto;
  //     height: 75px;
  //     background: #1e76af 0% 0% no-repeat padding-box;
  //     box-shadow: 0px 5px 6px #00000029;
  //     border-radius: 8px;

  //     & .user-info {
  //       padding: 25px 20px;
  //       height: 75px;
  //       display: flex;
  //       justify-content: space-between;
  //       & .user-hello {
  //         // height: 24px;
  //         text-align: left;
  //         font-size: 17px;
  //         letter-spacing: 0px;
  //         color: #ffffff;
  //         margin-top: -6px;
  //       }
  //       & .username-wrapper {
  //         display: flex;
  //         align-items: baseline;
  //         // padding-top: 4px;
  //         & .username {
  //           text-align: left;
  //           font-size: 17px;
  //           letter-spacing: 0px;
  //           color: #ffffff;
  //           margin-left: 4px;
  //           font-weight: bold;
  //         }
  //         & .username-unit {
  //           text-align: left;
  //           font-size: 17px;
  //           letter-spacing: 0px;
  //           color: #ffffff;
  //         }
  //       }
  //       & .username-button {
  //         display: flex;
  //         justify-content: space-between;
  //         align-items: center;
  //         & .modi-button {
  //           display: flex;
  //           width: 93px;
  //           height: 22px;
  //           border: 1px solid #ffffff;
  //           border-radius: 4px;
  //           background: #1e76af 0% 0% no-repeat padding-box;
  //           padding: 0;
  //           align-items: center;
  //           justify-content: center;
  //           // margin-left: 105px;
  //           & .button-title {
  //             text-align: left;
  //             font-size: 10px;
  //             letter-spacing: 0px;
  //             color: #ffffff;
  //           }
  //           & .userInfo-img img {
  //             // margin-bottom: 3px;
  //             height: 11px;
  //             width: 11px;
  //             cursor: pointer;
  //           }
  //         }
  //       }
  //     }
  //   }
  //   & .mobile-mypage {
  //     width: 100%;
  //     margin-top: 12px;
  //     background: #f3f8fb 0% 0% no-repeat padding-box;
  //     border-radius: 8px;
  //     display: flex;
  //     flex-wrap: wrap;
  //     align-content: stretch;
  //     padding: 16px;
  //     height: 162px;
  //     & .item:nth-child(1) {
  //       border-bottom: 1px solid #e8e8e8;
  //       border-right: 1px solid #e8e8e8;
  //       padding-bottom: 16px;
  //       padding-right: 16px;
  //     }
  //     & .item:nth-child(2) {
  //       border-bottom: 1px solid #e8e8e8;
  //       padding-left: 16px;
  //       padding-bottom: 16px;
  //     }
  //     & .item:nth-child(3) {
  //       border-right: 1px solid #e8e8e8;
  //       padding-top: 16px;
  //       padding-bottom: 0px;
  //       padding-right: 16px;
  //     }
  //     & .item {
  //       width: 50%;

  //       & .info-title {
  //         text-align: left;
  //         font-size: 12px;
  //         font-weight: bold;
  //         letter-spacing: 0px;
  //         color: #000000;
  //         opacity: 1;
  //         display: flex;
  //         // justify-content: center;
  //       }
  //       & .info-content-wrapper {
  //         height: 19px;
  //         margin-top: 11px;
  //         display: flex;
  //         align-items: center;
  //         justify-content: flex-end;
  //         & .info-content {
  //           text-align: center;
  //           font-size: 17px;
  //           font-weight: bold;
  //           letter-spacing: 0px;
  //           color: #1e76af;
  //           opacity: 1;
  //         }
  //         & .info-unit {
  //           text-align: right;
  //           margin-left: 5px;
  //           margin-top: 4px;
  //           font-size: 10px;
  //           letter-spacing: 0px;
  //           color: #000000;
  //           opacity: 1;
  //         }
  //       }
  //     }
  //   }
  // }
}
